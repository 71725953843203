@import "src/assets/sass/variables.scss";

@font-face {
  font-family: SlalomSans;
  src: url("../../assets/fonts/SlalomSans/SlalomSans_Regular.otf");
}

.team-profile-tile-container {
  flex-basis: 23.5%;
  min-width: 159px;
  max-width: 236.8px;
  min-height: 225px;
  border-radius: 10px;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  display: flex;
  font-family: SlalomSans;
  border: 2px solid $color-primary;
  box-shadow: 0px 4px 40px 0px #00000026;
  overflow: hidden;
  flex-direction: column;
}
.team-profile-tile-image {
  z-index: -10;
}
.team-profile-front-tile-container {
  width: 100%;
  padding: 0 8px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: $color-button-secondary-hover;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;

  font-family: "Slalom Sans";
  font-style: normal;
  color: $color-black;
}

.team-profile-name-container {
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
}

.team-profile-role-container {
  font-size: 14px;
  line-height: 30px;
  font-weight: 400, regular;
}

.about-info-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.team-profile-back-tile-container {
  width: 250px;
  height: 80px;
  font-weight: 400, regular;
  font-size: 16px;
  line-height: 19px;
  margin-top: 140px;
  position: absolute;
  text-align: center;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.team-profile-quote-container {
  margin-top: 20px;
  margin-left: 5px;
}

@media screen and (max-width: 1182px) {
  .profile-tile-margin {
    margin-right: 16px;
  }
}
