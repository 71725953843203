@import "src/assets/sass/variables.scss";

.event-card-container {
  font-family: SlalomSans;
  
  .app-card-content {
    height: 806px;
    display: flex;
    justify-content: space-between;
  }

  .event-card-right-arrow-icon {
    position: relative;
    top: 8px;
    padding-left: 8px;
  }

  @media screen and (max-width: $breakpoint-events-grid-container) {
    flex: 1 1 340px;
  }
}

// Continue Reading >
.flipped {
  .event-content {
    overflow-y: auto;
    max-height: 180px;
    @media screen and (max-width: $breakpoint-events-grid-container) {
      max-height: 180px;
    }
  }
}

.event-card-container-mobile {
  margin: 24px 0 0 0px;
}

@media screen and (max-width: 425px) {
  .event-card-container-mobile {
    width: 100%;
  }
}

/* Needed to override semantic-ui styles */
.ui.active.modal {
  display: flex !important;
  justify-content: center;
  box-shadow: none;
  max-width: 686px;
}

.ui.modal {
  background: none;
  border-radius: 10px;
}

.ui.modal > .icon:first-child + *,
.ui.modal > :first-child:not(.icon) {
  border-radius: 10px;
}

.ui.basic.modal > .close {
  top: 3.5rem;
  right: 6rem;
}

.ui.modal > .close {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  opacity: 0.8;
  font-size: 2em;
  color: #fff;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.625rem 0 0 0;
}

.modals.dimmer .ui.scrolling.modal .visible.transition {
  margin: 1rem auto;
  display: flex;
  justify-content: center;
}

.event-weekday {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.event-date {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: black;
  margin-bottom: 32px;
}

.event-time {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
}

.link {
  color: black;
  text-decoration: underline;
}

.event-details {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: $color-black;

  &__top-row {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
  }

  &__second-row {
    display: flex;
    gap: 36px;
    margin-bottom: 24px;
  }

  &__date {
    display: flex;
    flex-direction: column;
    font-family: Slalom Sans;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    justify-content: center;
    gap: 4px;
  }

  &__name {
    display: flex;
    font-family: Slalom Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: left;
    align-items: center;
  }

  &__location {
    display: flex;
    gap: 12px;
    height: 30px;
    align-items: center;

    &__market {
      font-weight: 700;
      font-size: 16px;
    }
  }

  &__speakers-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;
    margin-bottom: 24px;
    line-height: 12px;
    position: relative;
    top: 5px;
   
    overflow-y: auto;
    max-height: 200px;
    @media screen and (max-width: $breakpoint-events-grid-container) {
      max-height: 180px;
    }
  }

  &__speaker-container {
    display: flex;
    flex-direction: column;
    background: $color-secondary-200;
    gap: 8px;
    border-radius: 10px;
    text-align: center;
    padding: 16px;

    &__market {
      font-weight: 400;
    }
  }
}

.event-card-points-icon {
  width: 30px;
  height: 30px;
}

.event-details-expanded {
  height: 55%;
  overflow-y: auto;
  padding-right: 32px;
  margin-bottom: 30px;
  scrollbar-color: white transparent;
}

.event-card-title {
  font-family: SlalomSans;
  font-size: 25px;
  font-weight: 900;
  margin-bottom: 36px;
  line-height: 28px;
}

.event-detail-title {
  font-size: 25px;
  font-weight: 900;
  line-height: 32px;
  max-width: 350px;
}

.event-points-container {
  display: flex;
  width: 30px;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0%;
  // padding-bottom: 30px;
  .trophy-icon {
    width: 10px;
    height: 10px;
  }
  // padding-right: 25px;
}

.event-points {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  height: 30px;
}

.event-content {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 30px;
  width: 100%;
  word-wrap: break-word;
}

.event-location {
  font-size: 16px;
}

.event-card-btn-container-mobile {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 15%;
}

.event-btn {
  height: 48px;
  border-radius: 8px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  margin-bottom: 16px;
}

#add-to-calendar-btn {
  display: block;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
  width: 164px;
  height: 47px;
}

.event-card-primary-btn-wrapper {
  display: flex;
  justify-content: center;
}

.event-card-secondary-btn-wrapper {
  margin-left: -24px;
  margin-top: -12px;
  margin-bottom: 24px;

  .app-button-text {
    color: $color-primary-500;
  }
}

.see-details-btn {
  background: #244354;
  border: 3px solid #00d9ef;
  color: #1be1f2;
  box-shadow: 0px 0px 10px 1px #00d9ef;
}

.add-to-cal-btn {
  background: $color-slalom-primary;
  border: 3px solid white;
  color: white;
  box-shadow: 0px 0px 10px 1px $color-slalom-primary;
}

.event-speakers {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
}

.event-speaker {
  text-align: center;
  font-weight: 400;
}

.event-speaker-pic {
  border-radius: 50%;
  margin-bottom: 10px;
  border: 3px solid #00a4f9;
}

.event-speaker-container {
  display: flex;
  justify-content: space-around;
  padding: 15px 0 30px;
}

.btn-content-color {
  color: $color-slalom-primary;
}

// Button Animation not needed for SCL
// .event-card-btn-animation {
//   position: relative;
//   z-index: 0;
//   border-radius: 8px;
//   overflow: hidden;
//   padding: 3px;
// }

// .event-card-btn-animation::before {
//   content: "";
//   position: absolute;
//   z-index: -2;
//   left: -50%;
//   top: -50%;
//   width: 200%;
//   height: 400%;
//   background-color: #000;
//   background-repeat: no-repeat;
//   background-size: 100%100%, 50%50%;
//   background-position: 0 0, 100% 0, 100% 100%, 0 100%;
//   background-image: $color-button-gradient;
//   animation: animRotate 1s linear infinite;
// }

// .event-card-btn-animation::after {
//   content: "";
//   position: absolute;
//   z-index: -1;
//   left: 1px;
//   top: 1px;
//   width: calc(100% - 2px);
//   height: calc(100% - 2px);
//   border-radius: 8px;
// }

// @keyframes animRotate {
//   100% {
//     transform: rotate(1turn);
//   }
// }
