@import "src/assets/sass/variables.scss";

.faq-tile-container {
  justify-content: space-between;
  box-sizing: border-box;
  background: $color-bg-light;
  backdrop-filter: blur(40px);
  border-radius: 20px;
  cursor: pointer;
}

.faq-tile-title {
  flex: 1;
  color: $color-primary;
}

.faq-tile-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Slalom Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: $color-black;
  border-radius: 16px;
}

.faq-tile-body-container {
  color: $color-black;
  font-family: SlalomSans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-top: 16px;
}

.faq-tile-arrow-icon {
  margin-left: 5px;
}

.faq-tile-arrow-icon-up {
  top: 0.28px;
  left: 18.35px;
  transform: rotateX(180deg);
}
