@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

/* Dropdown Styles */
.dropdown-label {
  color: $color-white;
}

.dropdown-container {
  box-sizing: border-box;
  width: unset !important;
  display: flex;
  border: 1px solid $color-border-primary;
  border-radius: 10px;
  padding: 1px;
  min-height: 48px;
  margin-bottom: auto;

  &:hover {
    border: 2px solid $color-border-primary;
    padding: 0px;
  }
}

.ui.multiple.dropdown {
  border-radius: 1px;
  width: 175px;
  min-height: 48px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 50px;
  z-index: -1;
  overflow: visible !important;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  @include fontStandardBold();
  z-index: 10;
}

.ui.dropdown .menu > .item:hover {
  background-color: $color-surface-secondary;
}

.ui.dropdown .menu > .item:hover.active {
  background-color: unset;
}

.ui.dropdown .menu > .item {
  min-height: 42px;
  border-top: unset !important;
  position: relative;
}

a.ui.active.label:hover,
a.ui.labels .active.label:hover {
  background: $color-white;
  opacity: 0.1;
}

.ui.selection.active.dropdown .menu {
  border-radius: 10px;
  border: 1px solid $color-border-primary !important;
  opacity: 1;
  min-height: auto;
  max-height: 350px;
  z-index: 100;
  margin-top: 8px;
  margin-bottom: 8px;
}

// .ui.dropdown > .text,
.ui.selection.visible.dropdown > .text:not(.default),
.ui.selection.active.dropdown,
.ui.selection.active.dropdown.upward,
.ui.selection.dropdown:hover,
.ui.selection.dropdown:focus,
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:focus,
.ui.selection.active.dropdown.upward:hover,
.ui.selection.active.dropdown.upward:focus {
  border-color: transparent;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;

  & .menu {
    border-color: $color-border-primary;
  }
}

.ui.selection.dropdown .menu > .item {
  font-size: 16px;
  padding: 16px;
}

.ui.selection.dropdown .menu > .item:not(:last-child)::before {
  content: "";
  position: absolute;
  height: 1px;
  bottom: 0;
  background-color: $color-primary;
  width: 90%;
}

.ui.selection.dropdown {
  display: flex;
  width: 100%;
  min-height: 44px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  z-index: 0;
  flex-wrap: wrap;
  gap: 4px;
}

.ui.dropdown > .dropdown.icon:before {
  content: "";
  pointer-events: all;
}

.ui.dropdown > .divider.default.text {
  display: flex;
  padding: 0px;
  margin: auto;
}

.ui.active.selection.dropdown {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.ui.active.selection.dropdown > .dropdown.icon,
.ui.visible.selection.dropdown > .dropdown.icon {
  background-image: url("../../assets/images/up-arrow.svg");
  opacity: 1;
}

.ui.selection.dropdown > .dropdown.icon {
  position: absolute;
  right: 4px;
  background-image: url("../../assets/images/down-arrow.svg");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  padding: 0px;
  width: 24px;
  height: 24px;
}

.ui.multiple.dropdown > .label {
  display: flex;
  min-height: 36px;
  gap: 4px;
  text-align: left;
  border: 1px solid $color-primary-500;
  border-radius: 10px;
  background: $color-surface-secondary;
  font-size: 16px;
  line-height: 16px;
  color: $color-black;
  justify-content: center;
  align-items: center;
}

a.ui.active.label:hover,
a.ui.labels .active.label:hover {
  background-color: unset;
}

i.icon.delete:before {
  content: "";
  pointer-events: all;
}

.ui.label > .close.icon,
.ui.label > .delete.icon {
  background-image: url("../../assets/images/tooltip_close.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  margin: auto;
  font-size: unset;
  opacity: unset;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  background: none;

  &:hover {
    background: $color-surface-secondary;
  }
}
