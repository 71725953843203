@import "src/assets/sass/variables";
@import "src/assets/sass/mixins";

.tab-container {
  display: flex;
  width: 100%;

  & div {
    width: 100%;
  }
}

.tabs-options {
  display: flex;
  cursor: pointer;
  width: 80%;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.3em;
  text-align: center;
  text-transform: uppercase;
  color: $color-black;
  border-radius: 16px 16px 0 0;
  padding-top: 24px;
  padding-bottom: 24px;

  @media screen and (max-width: $breakpoint-md) {
    font-size: 20px;
  }
}

.tabs-option {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 4px solid $color-border-secondary;
}

.selected-tab-option {
  border-bottom: 8px solid $color-border-secondary;
  padding-bottom: 24px;
  padding-top: 4px;
}

.unselected-tab-option {
  padding-bottom: 24px;
}