@import "src/assets/sass/variables.scss";

.tooltip-container {
  position: relative;
  display: flex;
  height: auto;
  align-items: center;
  margin-left: auto;
}

.tooltip-popup {
  display: flex;
  position: absolute;
  width: 226px;
  z-index: 10;
  padding: 16px;
  color: $color-black;
  background-color: $color-button-secondary-hover;
  border-radius: 16px;
  top: 0;
  left: 10px;
  transform: translate(-100%, 0%);

  @media screen and (max-width: 320px) {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-left: 0px;
  }

  &__close {
    justify-self: flex-end;
    text-align: right;
    cursor: pointer;
    padding-top: 8px;
  }

  &__content {
    padding-right: 16px;
    padding-top: 8px;
  }
}

.tooltip-icon {
  cursor: pointer;
  border-radius: 20px;
  position: relative;
}
