@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

.about-list-component {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 16px;
  row-gap: 16px;

  @media screen and (max-width: $breakpoint-md) {
    display: flex;
    flex-direction: row;
    overflow: auto;
    flex-wrap: nowrap;
    text-size-adjust: 22px;
    margin-bottom: 11px;
  }
}
