@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.donate-description {
    margin-bottom: 32px;

    .app-card-content {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background-color: $color-surface-secondary;
        box-shadow: 0px 4px 40px 0px #00000026;
        border: 2px solid var(--Border-border-primary, $color-primary);

        p {
            flex-grow: 2;

            @media screen and (max-width: ($breakpoint-md)) {
                padding: 0px 16px;
                justify-content: left;
            }
        }

        .app-button-container {
            min-width: 190px;
            align-items: center;
            padding: 0px 16px;
            justify-self: flex-end;
            margin-left: auto;

            @media screen and (max-width: ($breakpoint-md)) {
                justify-self: flex-start;
                padding-left: 4px;
                padding-top: 8px;
                margin-left: 0;
            }
        }

        @media screen and (max-width: ($breakpoint-md)) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
    }
}

.donate-section-container {
    background: none;
    flex-direction: row;
    max-width: 1320px;
    margin: 0 auto;
}

.donate-container {
    color: $color-primary;
    font-size: 24px;
    display: flex;
    padding: 0px 16px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    min-width: 222px;

    .thumbsup-icon {
        max-width: 20%;
        max-height: 20%;
        padding-right: 15px;
    }

    @media screen and (max-width: ($breakpoint-md)) {
        padding-bottom: 16px;
        justify-content: flex-start;
    }
}