@import "src/assets/sass/variables.scss";
@import "src/assets/sass/mixins";

.welcome-modal {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: $color-black, $alpha: 0.5);
  transition: 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;

    @media screen and (max-width: $breakpoint-md) {
      padding-top: 10%;
    }
  }
}

.welcome-modal-container {
  width: 90%;
  margin: 25vh auto;
  background: $color-white;
  border: 2px $color-primary solid;
  border-radius: 20px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;

  @media screen and (min-width: $breakpoint-xl) {
    max-width: 860px;
  }

  @media screen and (min-width: $breakpoint-lg) {
    width: 65%;
  }

  @media screen and (max-width: $breakpoint-md) {
    background: $color-white;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    margin-top: 25%;
  }
}

.welcome-title {
  color: $color-black;
  text-transform: none;
  margin-left: 32px;
  font-weight: 400;
  line-height: 58px;

  &.section-title {
    font-size: 48px;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 34px;
      line-height: 40px;
    }
  }
}

.card-container {
  width: 100%;
}

.welcome-modal-body {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
  position: relative;

  @media screen and (max-width: $breakpoint-md) {
    padding-top: 0px;
    margin: 0 16px;
    gap: 16px;
  }
}

.welcome-modal-section {
  display: flex;
  gap: 24px;

  @media screen and (max-width: $breakpoint-md) {
    gap: 16px;
  }

  &__image {
    margin: auto 0;
    padding-left: 32px;

    @media screen and (max-width: $breakpoint-lg) {
      padding-left: 24px;
    }

    @media screen and (max-width: $breakpoint-md) {
      padding-left: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: $color-black;

    @media screen and (max-width: $breakpoint-md) {
      gap: 8px;
      margin-left: 0;
      justify-content: center;
    }

    &__title {
      font-weight: 700;
      font-size: 24px;
      color: $color-primary;
    }

    &__body {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-right: 32px;

      @media screen and (max-width: $breakpoint-lg) {
        padding-right: 24px;
      }
    }
  }
}

.welcome-modal-button-container {
  margin-top: 40px;
  margin-bottom: 40px;

  &>div>button.app-button.app-button-primary {
    padding: 1rem 2rem;
  }
}