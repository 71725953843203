@import "src/assets/sass/variables";

.kudos-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: $color-white;
  font-weight: bold;
  background: $color-button-gradient;

  // Thumbs Up
  .app-button__icon {
    margin-right: 1px;
    width: 17%;
    height: auto;
  }

  &:hover {
    color: $color-white;
    box-shadow: $color-dark-gray;
    background: $color-button-primary-hover;
  }

  &:disabled {
    color: $color-white;
    background-color: $color-gray;
  }

  // Kudos Given State (Static)
  &--submitted, &--submitted:hover, &--submitted:disabled {
    display: flex;
    justify-content: center;
    align-items: center;

    background: $color-button-kudos-pressed !important;
    color: $color-primary-500 !important;

    // Kudos Given
    .app-button__icon {
      margin-bottom: 1.5px;
      margin-left: -8px;
      width: 25%;
    }
  }
}

.app-button__icon {
  margin-right: 10px;
}

.link-button-container {
  display: flex;
}

.full-width {
  width: 100%;
}

.add-to-calendar-button {
  justify-content: center;
}

.join-now-button {
  display: flex;
  background: $color-button-gradient;
  justify-content: center;
  align-items: center;
&:hover {
    color: $color-white;
    box-shadow: $color-dark-gray;
    background: $color-button-primary-hover;
  }
}