@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

.footer {
  @include fontStandard();
  position: relative;
  width: 100%;
  min-height: 80px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: default;
  background: $color-primary;
  gap: 64px;
  color: $color-white;

  @media screen and (max-width: $breakpoint-lg) {
    flex-direction: column;
    padding: 16px 0;
    gap: 5px;
  }

  &-center {
    margin: 0 auto;

    @media screen and (min-width: $breakpoint-lg) {
      margin: 0 0;
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      max-width: 1440px;
    }
  }

  &-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 336px;

    @media screen and (max-width: $breakpoint-lg) {
      max-width: fit-content;
      padding-bottom: 8px;
    }

    @media screen and (max-width: 705px) {
      max-width: 240px;
      padding-bottom: 16px;
    }

    p {
      text-align: left;

      a {
        color: $color-white;
      }
    }
  }


  .flag-icon,
  .chat-icon,
  .mail-icon {
    line-height: 10px;
    padding-right: 16px;
  }
}