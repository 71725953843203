@import "src/assets/sass/_variables";

$menu-transition-time: 0.25s;

@mixin rotateMenuIcon($rotateDeg) {
  transform-origin: center;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -o-transform-origin: center;
  transform: rotate($rotateDeg);
  -webkit-transform: rotate($rotateDeg);
  -moz-transform: rotate($rotateDeg);
  -o-transform: rotate($rotateDeg);
  transition: $menu-transition-time ease-in-out;
  -webkit-transition: $menu-transition-time ease-in-out;
  -moz-transition: $menu-transition-time ease-in-out;
  -o-transition: $menu-transition-time ease-in-out;
}

/* Hamburger Nav */

.nav-menu-mobile {
  display: flex;
  position: relative;
  z-index: -1;

  span {
    cursor: pointer;
    align-self: center;
    color: $color-white;
  }
}

/* Hamburger Menu Icon */

#nav-icon {
  z-index: 990;
  width: 25px;
  height: 20px;
  position: relative;
  cursor: pointer;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-md) {
    #nav-icon {
      margin-right: 0;
    }
  }

  &.open {
    justify-content: center;

    span {
      position: absolute;

      &:nth-child(1) {
        @include rotateMenuIcon(45deg);
      }

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        @include rotateMenuIcon(-45deg);
      }
    }
  }

  span {
    display: block;
    height: 3px;
    width: 100%;
    background-color: $color-white;
    left: 0;
  }
}

/* Nav Popup Menu */

.nav-menu {
  list-style: none;
  position: fixed;
  background: $color-surface-inverted;
  left: 0;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  transition: top 0.3s ease-in-out;
  color: $color-white;

  @media screen and (max-width: 1182px) {
    &--closed {
      top: -300px;
    }

    &--open {
      top: 80px;
    }
  }

  /* Nav Menu Content */

  &__item {
    padding: 12px 40px;
    color: $color-black;

    @media screen and (max-width: $breakpoint-lg) {
      display: flex;
      justify-content: center;
    }

    @media screen and (max-width: $breakpoint-lg) {
      position: relative;
    }

    &:not(:last-child)::after {
      border-bottom: 1px solid $color-white;

      @media screen and (max-width: $breakpoint-lg) {
        background-color: #efefef;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0);
        width: 90%;
      }
    }

    &:hover {
      background-color: lighten($color-gray, 10%);
    }
  }

  &__link {
    color: $color-white;

    &.active {
      text-shadow: 0px 0px 15px $color-text-shadow;
      font-weight: bold;
    }
  }

  &__rankings {
    display: flex;
    height: 50px;
    padding: 1rem 40px;
    font-weight: 700;
    justify-content: space-between;
  }
}