@import "src/assets/sass/variables.scss";

.scroll-to-top-container {
  display: flex;
  justify-content: center;
  margin: 36px 0 36px 0;
}

.scroll-to-top-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: $color-button-primary;
  cursor: pointer;
}

.scroll-to-top-icon {
  width: 24px;
  //transform: rotate(90deg);
}
