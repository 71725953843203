@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

.quiz-description {
  margin-bottom: 32px;

  .app-card-content {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: $color-surface-secondary;

    p {
      flex-grow: 2;
      padding: 16px;
    }

    flex-direction: row;

    @media screen and (max-width: ($breakpoint-md)) {
      align-items: flex-start;
      flex-direction: column;
    }
  }
}

.quiz-description-container {
  display: flex;
  align-items: center;
  border-radius: 16px;
  color: $color-white;
}

.quiz-description-container.desktop {
  background: none;
  flex-direction: row;
}

.quiz-description-container.tablet,
.quiz-description-container.mobile {
  background: none;
  flex-direction: column;
}

.quiz-description-container p {
  font-size: 16px;
  padding-right: 32px;
}

.points-container {
  color: $color-primary;
  font-size: 24px;
  display: flex;
  padding: 16px;
  align-items: center;
  font-weight: 700;
  min-width: 230px;
  justify-content: normal; 
  .trophy-icon {
    width: 35%;
    height: 35%;
    padding-right: 30px;
    @media screen and (max-width: ($breakpoint-md)) {
      width: 21%;
      height: 21%;
    }
  }
}

.quiz-content {
  .app-card-content {
    padding: 16px;
    gap: 16px;
    background-color: $color-surface-secondary;
    box-shadow: 0px 4px 40px 0px #00000026;
    border: 2px solid $color-primary;
  }

  &__title {
    @include cardTitle();
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    line-height: 26px;

    text-align: left;

    @media screen and (max-width: $breakpoint-md) {
      text-align: start;
    }
  }

  &__section {
    padding: 32px 16px 16px;
  }
}

.quiz-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 16px;
  color: $color-black;
}

.quiz-question {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.quiz-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.quiz-answers-container {
  padding: 0px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.quiz-answers-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}

.quiz-button {
  @include buttonPrimary();
  @include fontStandard();
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  padding: 19px 24px;
  max-width: none;
  cursor: pointer;
  gap: 15px;
  width: 49%;
  border: 1px solid $color-primary;
  background-color: $color-white;

  flex-basis: 45%;

  @media screen and (max-width: $breakpoint-md) {
    flex-basis: 100%;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover:not(:disabled) {
    background-color: $color-white;
    color: $color-primary;
  }

  &-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid $color-primary;
    height: 25px;
    width: 25px;
    min-width: 25px;
    border-radius: 50%;

    &__inner {
      width: 15px;
      height: 15px;
      background-color: transparent;
      border-radius: 50%;
    }
  }

  &-answer {
    flex-grow: 1;
    text-align: left;
    overflow-wrap: anywhere;
    text-transform: capitalize;
  }

  &-description {
    display: inline-block;
    padding: 10px 75px 10px 25px;
  }

  &-selected,
  &-selected:focus {
    font-weight: 400;
    border: 2px solid $color-primary;
    color: $color-primary;

    .quiz-button-circle {
      background: $color-white;

      &__inner {
        background-color: $color-button-primary;
      }
    }
  }

  &:disabled {
    background-color: $color-white;
    border: 1px solid $color-primary;
    color: $color-black;
    .quiz-button-circle {
      border: 1px solid $color-primary;
      &__inner {
        background-color: transparent;
      }
    }
  }

  &-correct,
  &-correct:disabled {
    background-color: $color-input-success-bg;
    border: 1px solid $color-input-success-border;
    color: $color-input-success-text;
    .quiz-button-circle {
      border: 1px solid $color-success;
      &__inner {
        background-color: $color-success;
      }
    }
  }

  &-wrong,
  &-wrong:disabled {
    background-color: $color-input-error-bg;
    border: 1px solid $color-input-error-border;
    color: $color-input-error-text;
    .quiz-button-circle {
      border: 1px solid $color-error;
      &__inner {
        background-color: $color-error;
      }
    }
  }
}

.quiz-submission {
  &-container {
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;

    background: none;
    justify-content: flex-end;

    @media screen and (max-width: $breakpoint-md) {
      background: none;
    }
  }

  &-content {
    color: $color-black;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;

    .app-button-generic-container {
      margin-bottom: 8px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    p {
      font-size: 14px !important;
    }

    @media screen and (max-width: 320px) {
      max-width: 130px;

      p {
        text-align: center;
      }
    }
  }
}

.quiz-explanation {
  font-size: bold;
  color: $color-black;
  margin-top: 16px;
}

.section-qotd {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;

  .section-header__icon,
  .section-header__circle {
    display: none;
  }
}

.quiz-of-the-day {
  background: none;
}

.overlay {
  position: absolute;
  z-index: 2;
}
