@import "src/assets/sass/variables.scss";
@import "src/assets/sass/mixins.scss";


.about-link:hover {
  color: white;
}

.about-page-text-container {
  background: $color-bg-light;
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 20px;
  box-sizing: border-box;
  padding: 32px;
  flex-direction: column;
  min-height: 300px;

  @media screen and (max-width: $breakpoint-md) {
    background: $color-bg-light;
    backdrop-filter: blur(40px);
    border-radius: 20px;
    box-sizing: border-box;
    padding: 32px;
    flex-direction: column;
  }
}

.about-page-text-body-container {
  font-family: SlalomSans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 20px;
  color: $color-black;
}

.about-header {
  font-family: Slalom Sans;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  text-transform: capitalize;
  color: $color-primary;

  font-size: 48px;
  line-height: 57.6px;
  margin-top: 48px;
  margin-bottom: 16px;

  @media screen and (max-width: $breakpoint-md) {
    font-size: 34px;
    line-height: 41px;
    margin-bottom: 16px;
  }
}

.about-welcome-content {
  display: flex;
  width: 100%;

  @media screen and (max-width: $breakpoint-md) {
    display: flex;
    flex-direction: column;
  }
}

.about-welcome > section {
  width: 100%;
}

.welcome-section:first-child {
  @media screen and (max-width: $breakpoint-md) {
    margin-bottom: 32px;
  }
}

.welcome-section-content {
  margin-right: 1.5rem;

  @media screen and (max-width: $breakpoint-md) {
    margin-right: 0;
  }
}

.about-section-title {
  padding-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  gap: 10px;
  display: flex;
  align-items: center;
  color: $color-primary;
  margin-bottom: 16px;
}


.about-notes-section {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  @media screen and (max-width: $breakpoint-md) {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    gap: 20px;
    overflow-x: auto;
  }
}

.contact-section-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  max-height: 331px;
  @media screen and (max-width: $breakpoint-md) {
    max-height: 400px;
    overflow-x: auto;
  }
}

.contact-section-title {
  color: $color-black;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 16px;
}

.contact-card {
display: flex;
flex: 1;
margin-bottom: 0;
  @media screen and (max-width: $breakpoint-md) {
    flex-shrink: 0.2;
  }
  @media screen and (max-width: $breakpoint-sm) {
    flex-shrink: 0.1;
  }
}

.about-page-card-text-body-container {
  font-family: SlalomSans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin: 0;
  color: $color-black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  @media screen and (max-width: $breakpoint-md) {
    width: 220px;
  }
}

.about-page-btn-container {
  display: flex;
  text-align: center;
  padding: 32px 0px 16px;
}

.about-page-card-footer {
  display: flex;
  justify-content: center;
  margin-top: auto;
  align-items: flex-end;
  
  @media screen and (max-width: $breakpoint-md) {
   //margin-top: auto;
  }
  
  @media screen and (max-width: $breakpoint-sm) {
    //margin-top: 36px;
  }
}