@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

html,
body,
#root,
.App {
  margin: 0 !important;
  height: 100%;
  font-family: Slalom Sans !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
.h1 {
  @include global-h1;
}

h2,
.h2 {
  @include global-h2;
}

h3 {
  @include header-common;
}

a {
  color: $color-button-primary;
  font-weight: bold;
}

a:hover {
  //text-decoration: underline;
  color: $color-button-primary-hover;
}

body ::-webkit-scrollbar {
  max-height: 10px;
}

body ::-webkit-scrollbar-thumb {
  background: $color-secondary-500;
  width: 160px;
}

p {
  font-size: 16px;
}

.bug-banner {
  background-color: #dd7d25;
  height: 114px;
  margin: 0 80px;
  font-size: 16px;
}

.title-text {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.title-text-large {
  font-size: 32px;
  line-height: 38px;
}

.title-text-mobile {
  font-size: 18px;
  line-height: 22px;
}

.page-large {
  background-size: 100% 630px;
}

.page-mobile {
  background-size: 100% 370px;
}

.page-title {
  position: absolute;
  right: 0;
  float: right;
  margin-top: 2em;
  width: 15em;
  color: white;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 0.3em;
  line-height: 90px;
}

.section-container {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
}

@media screen and (max-width: $breakpoint-md) {
  .section-container {
    width: 100%;
  }
}

.section-title {
  margin: 32px 24px 32px 0px;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: $color-slalom-primary;
  text-transform: uppercase;

  @media screen and (max-width: $breakpoint-md) {
    .section-title {
      margin: 32px 0px;
      font-size: 18px;
      line-height: 21.6px;
    }
  }
}

.app-button {
  @include button();
}

.app-button-primary {
  @include buttonPrimary();
  height: 51px;
  min-width: 130px;
}

.app-button-secondary {
  @include buttonSecondary();
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.app-button-text {
  background: none;
  color: $color-slalom-primary;
  font-family: "Slalom Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  line-height: 19px;
  padding: 12px 24px 12px 24px;

  &:disabled {
    color: $color-gray;
    cursor: not-allowed;

    &:hover {
      cursor: not-allowed;
    }
  }

  &:hover {
    color: $color-slalom-primary-dark;
    cursor: pointer;
  }
}

.disabled {
  background: #b3b5bd;
  color: black;
}

.app-card-display {
  border-radius: 20px;
  background: $color-bg-light;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
}

.flex-container {
  width: 100%;
  display: flex;
}

.spaced-section {
  margin-top: 64px;
}

.page-container {
  max-width: 1320px;
  margin: auto;
}
