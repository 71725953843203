@import "src/assets/sass/variables";
@import "src/assets/sass/mixins";

.events-container {
  max-width: 1320px;
  margin: auto;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  .app-card-display {
    display: flex;
    flex-wrap: wrap;

    .filter-row {
      display: flex;
      flex-basis: 100%;

      @media screen and (max-width: $breakpoint-md) {
        flex-direction: column;
      }
    }

    .app-button-primary {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.filter-container {
  @include card();
  position: relative;
  z-index: 1;
  margin-bottom: 24px;
  display: flex;
  padding: 32px;
  margin-bottom: 28px;
  border: 2px solid $color-border-primary;

  & div {
    width: 100%;

    & > p {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $color-text-tertiary;

      &.previous-event-text {
        color: $color-black;
      }
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    min-width: 332px;
  }
}

.filter-row {
  display: flex;
  justify-content: flex-start;
  padding: 12px;
  gap: 20px;

  @media screen and (max-width: $breakpoint-md) {
    flex-direction: column;
  }
}

.filter-entry {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.events-filter {
  @media screen and (max-width: $breakpoint-md) {
    margin-right: 0;
    position: relative;
    background-color: $color-gray;

    &.desktop {
      margin-right: 20px;
    }
  }
}

.ui.dropdown:not(.button) > .default.text {
  font-family: "Slalom Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $color-black;
}

.ui.multiple.dropdown > .text {
  font-weight: 700;
  position: static;
  padding: 1rem;
  max-width: 100%;
  margin: unset;
  line-height: unset;
}

.ui.multiple.dropdown {
  box-shadow: unset !important;
  min-width: 0;
}

.selected.item {
  font-weight: 400 !important;
}

.selected.item:hover,
.item:hover {
  font-weight: 700 !important;
}

.dropdown-label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

.modals.dimmer .ui.scrolling.modal .visible.transition {
  margin: 1rem auto;
  display: flex;
  justify-content: center;
}

.event-card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  overflow-x: auto;
  margin-bottom: 48px;
  position: relative;
  z-index: 0;

  @media screen and (max-width: $breakpoint-events-grid-container) {
    display: flex;
    flex-direction: row;
  }
}

.claim-points-spacer {
  margin-bottom: 48px;
}

.event-about-container {
  display: flex;
  width: 100%;
}

.temp-card-container {
  display: flex;
  max-width: 50%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: -30px;
}

.claim-points-container {
  position: absolute;
  max-width: 95%;
  top: 75%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.selected-event-option {
  border-bottom: 4px solid $color-slalom-primary;
  position: relative;
  background: $color-gray;
  color: $color-slalom-primary;
  padding-top: 4px;
}

.events-option {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.events-options {
  display: flex;
  cursor: pointer;
  width: 80%;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.3em;
  text-align: center;
  text-transform: uppercase;
  background-color: $color-bg-light;
  color: $color-black;
  border-bottom: 1px solid $color-slalom-primary;
}

.recordings-button-container {
  border: none;
  height: 100%;
}
