@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

.nav-options {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  height: 52px;
  max-width: 370px;
  text-decoration: none;
  margin-left: 20em;
  font-size: 16px;
}

.nav-option {
  padding: 12px 16px;
  text-decoration: none;
  border-width: 2px;
  border: 2px solid transparent;
  border-radius: 4px;
  font-weight: normal;

  &:active {
    color: $color-slalom-primary;
  }

  &.active {
    font-weight: bold;

    &>span {
      border-bottom: 2px solid white;
      padding-bottom: 8px;
    }
  }

  &:hover {
    text-decoration: none;
    text-shadow: 0px 0px 0.6px $color-slalom-primary;
  }
}

.nav-link-text {
  &:before {
    content: "";
    position: absolute;
    visibility: hidden;
    height: 2px;
    width: 0;
    right: 50%;
    bottom: 7px;
    transition: all 0.2s ease-in-out;
  }

  color: $color-white;
  gap: 10px;

  &:hover {
    color: $color-secondary-200;
    text-shadow: 1px 0 $color-secondary-200; // Use this instead of text-weight to prevent other elements from shifting over
  }
}