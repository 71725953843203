@import "src/assets/sass/variables.scss";

.app-card {
  color: #FFF;
  width: 100%;
  font-family: Slalom Sans;
  margin-bottom: 40px;
}

.app-card-title {
  font-size: 32px;
  color: $color-slalom-primary;
  text-transform: uppercase;
}


.app-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  padding: 20px;
  background: $color-white;
  color: $color-black;
  border-radius: 20px;
  box-shadow: 0px 4px 40px 0px #00000026;
  border: 2px solid $color-primary;
}

.app-card-header {
  margin: 64px 0px 32px 0px;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1182px) {
  .app-card-title {
    font-size: 18px;
  }
}