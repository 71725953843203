@import "src/assets/sass/variables.scss";
@import "src/assets/sass/mixins.scss";

.page-banner {
  margin-top: -10px;
}

.workday-link {
  display: contents;
}

.leaderboard-section .leaders {
  @media screen and (max-width: $breakpoint-sm) {
    justify-content: flex-start;
  }
}
