@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

.section-container {
  width: 100%;
  color: $color-black;
}

.section-title-container {
  display: flex;
  margin: auto;
  height: 2.4em;
  justify-content: space-evenly;
  margin-bottom: 50px;
  text-transform: capitalize;
  color: black;
}

.other-ways-to-earn-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-evenly;

  .app-card {
    min-height: 377px;
  }
}

.other-ways-to-earn-tile-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  height: 100%;
  display: block;
}

.tile-title {
  font-family: Slalom Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: $color-black;
  padding: 0px 0px 16px 0px;
}

.trophy-icon {
  width: 7%;
  top: 0.13px;

  @media screen and (max-width: ($breakpoint-lg)) {
    width: 10%;
    height: 10%;
  }
}

.points-value {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: $color-primary;
  padding-left: 16px;
}

.tile-points {
  display: flex;
  font-size: 16px;
}

.tile-description {
  font-family: "Slalom Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $color-black;
  padding: 16px 0;
}

.tile-button-container {
  margin: 0 auto;
}

@media screen and (max-width: 1280px) {
  .other-ways-to-earn-container {
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 20px;
  }

  .tile-width {
    min-width: 320px;
  }

  .tile-title {
    font-family: "Slalom Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }

  .tile-button {
    width: 100%;
    margin: 16px 16px 16px 0;
    padding: 0 16px 0 16px;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .tile-width {
    min-width: 260px;
  }

  .app-button-primary {
    font-size: 1rem;
  }
}