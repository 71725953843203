@import "src/assets/sass/variables.scss";

.top-leader-card {
  font-family: "Slalom Sans";
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
  background: rgba(255, 238, 143, 1);
  min-height: 199px;
  max-height: 199px;
  max-width: 390px;
}

.top-leader-card--0,
.top-leader-card--1 {
  margin-right: 16px;
}

.top-leader-content {
  margin: 16px 24px 16px 30px;
  min-width: 120px;
  width: 100%;
  color: black;
  display: flex;
  flex-direction: column;
}
.top-leader-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.top-leader-stat-h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.top-leader-stat-h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.top-leader-stat-position-points {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}
.top-leader-stat-h2-market {
  margin-bottom: 24px;
}

.image-0 {
  background-image: url("../../assets/images/leaderboard-gold-2.png");
  background-position: center;
}
.image-1 {
  background-image: url("../../assets/images/leaderboard-silver-2.png");
}
.image-2 {
  background-image: url("../../assets/images/leaderboard-bronze-2.png");
}

.position-image-container {
  height: 199px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 216px;
}

.position-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 216px;
  height: 100%;
}

@media screen and (max-width: $breakpoint-md) {
  .top-leader-card {
    flex-direction: column;
    min-width: 168px;
    max-width: 216px;
    min-height: 299px;
    width: 100%;
  }
  .top-leader-content {
    margin: auto;
    padding-top: 12px;
    align-items: center;
    justify-content: center;
    height: 209px;
    gap: 4px;
  }
  .top-leader-card--0,
  .top-leader-card--1 {
    margin-right: 16px;
  }
  .position-image-container {
    align-items: end;
    width: auto;
  }
  .position-image {
    width: 204px;
    height: 90px;
    min-width: 0px;
  }

  .image-0 {
    background-image: url("../../assets/images/leaderboard-gold-2-sm.png");
  }
  .image-1 {
    background-image: url("../../assets/images/leaderboard-silver-2-sm.png");
  }
  .image-2 {
    background-image: url("../../assets/images/leaderboard-bronze-2-sm.png");
  }
}
