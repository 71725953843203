$color-slalom-primary: #0c62fb;
$color-slalom-primary-dark: darken(#0c62fb, 10%);
$color-bg-light: #ebebeb;
$color-bg-border: #9c9c9c;
$color-black: #000000;
$color-white: #ffffff;
$color-gray: #7c7c7c;
$color-light-gray: lighten($color-gray, 20%);
$color-dark-gray: darken($color-gray, 20%);

$color-text-shadow: $color-gray;

$color-success: #135b09;
$color-error: #7e0b17;

// breakpoints
$breakpoint-sm: 375px; // breakpoint for mobile
$breakpoint-md: 768px; // breakpoint for tablet
$breakpoint-lg: 1440px;
$breakpoint-xl: 1920px;

// page breakpoints
$breakpoint-events-grid-container: 969px;

$color-primary: #6139a3;
$color-text-secondary: #005c40;
$color-border-secondary: #70c5b5;
$color-card-secondary: #d7fff8;
$color-input-error-bg: #ffa6af;
$color-input-error-border: #e95a5a;
$color-input-error-text: $color-error;
$color-input-success-bg: #d3facc;
$color-input-success-border: #5cd448;
$color-input-success-text: $color-success;

// colors below added for 2024 Slalom City Limits
// theme
$color-accent-1: #4c4bad;
$color-secondary-500: #f57b6b;
$color-secondary-400: #ff8f78;
$color-accent-2: #fcc8c2;
$color-accent-3: #f5eccd;
$color-primary-500: #8661c2;
$color-primary-700: #6139a3;
$color-secondary-300: #f7cb83;
$color-secondary-200: #ffee8f;

// neutrals
$color-white-15: $color-white;
$color-grey-50: #f1f1f1;
$color-grey-200: #d3d3d3;
$color-grey-400: #afafaf;
$color-grey-600: #7c7c7c;
$color-grey-800: #343434;
$color-grey-900: #202020;

// system
$color-danger-red-500: #d95a5a;
$color-warning-500: #ffe601;
$color-success-green-100: #d3facc;
$color-danger-red-100: #ffa6af;
$color-success-green-900: #135b09;
$color-danger-red-900: #7e0b17;
$color-success-green-500: #5cd448;

// surface
$color-surface-primary: $color-white;
$color-surface-secondary: $color-secondary-200;
$color-surface-inverted: $color-primary-700;
$color-surface-globalbg: $color-accent-1;

// text
$color-text-primary: $color-black;
$color-text-secondary: $color-primary-700;
$color-text-inverted-primary: $color-white;
$color-text-tertiary: $color-grey-600;
$color-text-inverted-secondary: $color-secondary-200;

// button primary
$color-button-primary-default-label: $color-white;
$color-button-primary-disabled: $color-grey-600;
$color-button-primary: $color-primary-500;
$color-button-primary-hover: $color-primary-700;
$color-button-primary-pressed: $color-primary-700;

// button secondary
$color-button-secondary-default: $color-white;
$color-button-secondary-default-label: $color-primary-500;
$color-button-secondary-hover: $color-secondary-200;
$color-button-secondary-disabled: $color-grey-600;
$color-button-secondary-pressed: $color-white;
$color-button-secondary-disabled-label: $color-white;

//button gradients
$color-button-gradient: linear-gradient(
  96.58deg,
  $color-accent-1 8.47%,
  $color-primary-500 85.4%
);
$color-button-kudos-pressed: linear-gradient(
  96.58deg,
  $color-secondary-200 8.47%,
  $color-secondary-300 85.4%
);

// border
$color-border-primary: $color-primary-700;
$color-border-secondary: $color-secondary-500;
$color-border-inverted: $color-white;

// form
$color-form-correct-bg: $color-success-green-100;
$color-form-incorrect-bg: $color-danger-red-100;
$color-form-correct-label: $color-success-green-900;
$color-form-incorrect-label: $color-danger-red-900;
$color-form-default-bg: $color-white;
$color-form-default-border: $color-primary-700;
$color-form-hover-border: $color-primary-700;
$color-form-focus-border: $color-primary-700;
$color-form-correct-border: $color-success-green-500;
$color-form-incorrect-border: $color-danger-red-500;
$color-form-disabled-bg: $color-grey-50;
$color-form-disabled-border: $color-grey-600;

// form button
$color-button-text-default-label: $color-primary-500;
$color-button-text-pressed-label: $color-primary-700;
$color-button-text-hover-label: $color-primary-700;
$color-button-text-disabled-label: $color-grey-600;

// form button nav
$color-button-nav-default-label: $color-white;
$color-button-nav-hover-label: $color-white;
$color-button-nav-selected-label: $color-white;
