@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

.section-header {
  display: flex;
  justify-content: left;
  text-align: left;
  width: 100%;

  &__title {
    @include sectionHeader();

    @media screen and (max-width: $breakpoint-md) {
      font-size: 34px;
    }
  }

  &__circle {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 12px;
    height: 12px;

    @media screen and (max-width: $breakpoint-sm) {
      display: none;
    }

    @media screen and (max-width: $breakpoint-md) {
      width: 9px;
      height: 9px;
    }
  }

  &__icon {
    min-width: 21px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0 15px;
    width: 33px;
    height: 33px;

    &.flip {
      transform: scaleX(-1);
    }

    @media screen and (max-width: $breakpoint-sm) {
      width: 21px;
      height: 21px;
    }

    @media screen and (max-width: $breakpoint-md) {
      width: 26px;
      height: 26px;
    }
  }
}
