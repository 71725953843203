@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

.nav-logo-content {
  margin-right: auto;
  display: flex;
  justify-self: flex-start;
  z-index: 10;

  @media screen and (max-width: $breakpoint-md) {
    justify-self: flex-start;
    z-index: 10;
  }
}

.nav-logo > img {
  height: 50px;
}

.top-left {
  position: absolute;
  left: 24px;
  top: 24px;
  margin-left: 0;
}
