@import "src/assets/sass/variables.scss";
@import "src/assets/sass/mixins";

.leaderboard-section {
  width: 100vw;

  .leaderboard-container {
    border-radius: 45px;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 32px;
  }

  .Market-container,
  .Office-container {
    border-radius: 16px;
    border: unset;
  }

  .Local-container {
    border-radius: 16px;
  }

  .leaders {
    display: flex;
    gap: 4px;
    margin-top: 16px;
    margin-bottom: 32px;
    justify-content: center;
    &.leaders-small {
      margin-bottom: 0px;
    }
  }

  .leaders-mobile {
    position: relative;
    margin-top: 16px;
    margin-bottom: 0px;
    padding-bottom: 8px;
  }

  .leader-info {
    margin: 30px 0 0 30px;
  }

  .leader-place {
    background-size: cover;
    background-repeat: no-repeat;
    border: 3px solid #008bff;
    border-radius: 16px;
    background-color: rgba(9, 9, 28, 0.5);
    margin-right: 15px;
  }

  .leader-place-large {
    height: 257px;
    width: 387px;
  }

  .leader-place-tablet {
    height: 300px;
    min-width: 182px;
  }

  .leader-place-mobile {
    height: 230px;
    min-width: 152px;
  }

  .leader-avatar {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    border: 3px solid #008bff;
  }

  .leader-stats {
    font-family: Slalom Sans;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }

  .leader-market {
    font-family: Slalom Sans;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .leader-points {
    font-family: Slalom Sans;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .leader-ranking {
    font-family: Slalom Sans;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }

  .leader-listing-container {
    display: grid;
  }

  .leader-listing-headers {
    display: grid;
    grid-template-columns: 10% 5% 45% 20% 20%;
    grid-column-start: 1;
    grid-column-end: -1;
    justify-items: center;
    align-items: center;
    margin-bottom: 25px;
    margin-top: 15px;
    font-size: 25px;
    font-weight: bold;
    line-height: normal;
  }

  #name-header {
    grid-column-start: 3;
    grid-column-end: 4;
  }

  #market-header {
    grid-column-start: 4;
    grid-column-end: 5;
  }

  #points-header {
    grid-column-start: 5;
    grid-column-end: 6;
  }

  .leader-listing {
    display: grid;
    grid-template-columns: 5% 25% 40% 30%;
    height: 72px;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: -1;
    justify-items: center;
    align-items: center;
    color: black;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 20px;

    & > *:first-child {
      justify-self: start;
    }

    & > *:last-child {
      justify-self: end;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:not(:first-child) {
      margin-top: 16px;
    }

    &:not(:nth-last-child(2)) {
      margin-bottom: 16px;
    }

    &__rank {
      font-weight: 900;
      grid-column-start: 1;
      grid-column-end: 2;
    }

    &__participant {
      width: 100%;
      display: flex;
      align-items: center;

      & > img {
        margin-right: 10px;
      }
    }

    &__avatar {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }

  .leader-listing-market {
    grid-template-columns: 10% 50% 20% 20%;
  }

  .listing-text-style {
    font-family: Slalom Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.28px;
  }

  .listing-name {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
  }

  .listing-market {
    grid-column-start: 4;
    grid-column-end: 5;
  }

  .listing-points {
    grid-column-start: 5;
    grid-column-end: 6;
  }

  .leaderboard-default-msg {
    font-family: Slalom Sans;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: $color-white;
    padding: 32px;
  }

  .leaderboard-tooltip a {
    color: $color-slalom-primary;
    text-decoration: underline;
  }

  .leaderboard-tooltip {
    display: flex;
    gap: 12px;
  }
  .leaderboard-gap:not(:last-child) {
    border-bottom: 2px solid #e6e6e6;
  }

  .app-card-content {
    border-radius: 16px;
    padding: 24px;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .leaderboard-section {
    .leaders {
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      margin-bottom: 0;
      overflow-y: hidden;
      padding-bottom: 20px;
    }
    .leaderboard-gap:not(:last-child) {
      border-bottom: 2px solid #f57b6b;
    }
  }
}
