@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

.error-page {
  background: url("../../assets/images/pages/error-xl.png") center/cover no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: $breakpoint-lg) {
    background: url("../../assets/images/pages/error-lg.png") center/cover no-repeat;
  }

  @media screen and (max-width: $breakpoint-md) {
    background: url("../../assets/images/pages/error-md.png") center no-repeat;
    background-size: 100% 100%;
  }

  @media screen and (max-width: $breakpoint-sm) {
    background: url("../../assets/images/pages/error-sm.png") center no-repeat;
    background-size: 100% 100%;
  }
}

.error-btn-container {
  margin: 0 10px 85px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .app-button {
    min-width: 345px;
  }

  @media screen and (max-width: $breakpoint-md) {
    .app-button {
      min-width: 280px;
    }

    margin: 0 10px 85px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin: 0 0 60px 0;
  }
}

.error-btn-text-only {
  background: none;
  margin: 0 18px 0 0;
  border-radius: 50px;
  border: none;
  height: 48px;
  color: #ffffff;
  box-shadow: none;
  filter: none;
}

.app-button-secondary:visited {
  background: none;
  box-shadow: none;
}

.error-btn-text-only:hover {
  background: none;
  box-shadow: none;
  color: #b94b65;
  filter: none;
}

.error-btn-text-only:active {
  background: none;
  box-shadow: none;
  color: #ffffff;
  filter: drop-shadow(0px 0px 10px #c094bc);
}

.btn-label {
  font-family: "Slalom Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.btn-label-primary {
  font-family: "Slalom Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
@media screen and (max-width: $breakpoint-md) {
  .error-page-container-large {
    margin: 0 60px;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .error-page-container-mobile {
    margin: 0 40px;
    display: flex;
    flex-direction: column;
  }
}
