@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

.prelaunch-background {
  background: url("../../assets/images/pages/prelaunch-xl.png") center/cover
    no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 1900px;

  @media screen and (max-width: $breakpoint-lg) {
    background: url("../../assets/images/pages/prelaunch-lg.png") center/cover
      no-repeat;
    height: 1469px;
  }

  @media screen and (max-width: $breakpoint-md) {
    background: url("../../assets/images/pages/prelaunch-md.png") center/cover
      no-repeat;
    height: 1497px;
  }

  @media screen and (max-width: 665px) {
    background: url("../../assets/images/pages/prelaunch-sm.png") no-repeat;
    background-size: cover;
    height: 2231px;
  }
}

.prelaunch-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__buttons {
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: center;
    margin-top: 1040px;

    @media screen and (max-width: $breakpoint-lg) {
      margin-top: 620px;
    }

    @media screen and (max-width: $breakpoint-md) {
      margin-top: 540px;
      flex-direction: column;
    }

    @media screen and (max-width: 665px) {
      margin-top: 420px;
    }
  }

  // 670px

  &__set-list {
    align-self: center;
    margin-top: 40px;
    margin-inline: 60px;
    margin-bottom: auto;
    max-width: 1320px;
    padding: 48px;
    background: $color-white;
    border: 2px solid $color-border-primary;
    border-radius: 20px;

    @media screen and (max-width: $breakpoint-md) {
      margin-inline: 30px;
      padding: 32px;
    }

    @media screen and (max-width: 665px) {
      margin-inline: 20px;
    }

    &__day-entry {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &__date {
        color: $color-primary;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        text-align: left;
      }

      &__list {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;

        &__speaker {
          display: inline;

          &:not(:first-child):before {
            content: "•";
            margin-right: 4px;
            margin-left: 4px;
            color: $color-primary;
          }

          &:nth-child(n + 15):before {
            content: "\A";
            white-space: pre;
          }
        }
      }
    }
  }
}

.divider-prelaunch {
  text-align: center;
  height: 0px;
  margin: 8px -16px 24px;
  border: 1px solid $color-secondary-500;
}
