@import "src/assets/sass/variables.scss";
@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

.FAQ-container-desktop {
  margin-top: 450px;
}

.FAQ-container-tablet,
.FAQ-container-mobile {
  margin-top: 200px;
}

.faq-page {
  .page-container-large {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
  }

  .section-title {
    @include sectionHeader();

    @media screen and (max-width: $breakpoint-md) {
      font-size: 34px;
      line-height: 40px;
    }
  }
}

.faq-header {
  @include sectionHeader();
  p {
    font-size: 48px;
    font-weight: 400;
    line-height: 58px;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 34px;
      line-height: 40px;
    }
  }
}

.faq-content-wrapper {
  display: flex;

  &.sticky {
    .table-of-contents {
      position: fixed;
      top: 30px;
    }

    .faq-sections-wrapper {
      margin-left: 465px;

      @media screen and (max-width: $breakpoint-md) {
        margin-left: 0px;
      }
    }
  }
}

.table-of-contents {
  align-self: baseline;
  background: $color-white;
  margin-right: 24px;
  padding: 24px 16px;
  width: 440px;
  border-radius: 20px;
  box-shadow: 0px 4px 40px 0px #00000026;
  border: 2px solid $color-primary;

  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }

  .title {
    color: $color-accent-1;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
  }

  a {
    display: block;
    font-size: 16px;
    margin-bottom: 16px;
    padding-left: 6px;

    &.active {
      border-left: 2px solid $color-primary;
      color: $color-primary;
    }
  }
}

.faq-sections-wrapper {
  margin-bottom: 48px;
  display: block;
  width: calc(100% - 464px);

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
