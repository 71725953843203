@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

.navigation-bar-container {
  width: 100vw;
  height: 80px;
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 999;
  transform-style: preserve-3d;
  background-color: $color-surface-inverted;
  align-items: center;
  justify-content: flex-end;
  padding-left: 15px;
  margin-right: auto;
  margin-bottom: -80px;
  min-width: 320px;

  @media screen and (max-width: $breakpoint-md) {
    margin-bottom: -80px;
    align-items: right;
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin-bottom: -80px;
    align-items: center;
    padding-right: 10px;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 10px;
}

.nav-user-container-desktop {
  display: flex;
  // gap: 16px;
  margin-right: 10em;
}

// replace with $breakpoint-md once component has updated breakpoint
@media screen and (max-width: $breakpoint-md) {
  .nav-user-container {
    margin-left: 0;
  }
}

.nav-points {
  font-size: 16px;
  align-self: center;
  cursor: default;
  color: $color-white;
  font-weight: 700;
  display: block;
  padding-left: 15px;

  @media screen and (min-width: $breakpoint-lg) {
    padding-left: 24px;
  }
}

.nav-user {
  margin: 15px;
  height: 50px;
  width: 50px;
  align-self: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-user-container {
  display: flex;
  flex-direction: row;
  // gap: 16px;
  align-items: center;
  // margin-left: auto;
}

.user-container {
  display: flex;
  flex-direction: row;
}

.nav-user-img {
  border: 1px solid $color-white;
  border-radius: 50%;
  background: $color-slalom-primary;
}