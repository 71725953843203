@import "src/assets/sass/variables.scss";

.points-card {
	font-size: 20px;
	font-weight: 700;
	line-height: normal; 
	text-align: center;
	color: #6139A3; 
	background: #FFEE8F;
	padding: 12px 16px;
}