@import "src/assets/sass/_variables";
@import "src/assets/sass/_mixins";

.wrap-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: -80px;
}

.wrap-page.tablet {
  margin-bottom: -140px;
}

.wrap-page.mobile {
  justify-content: flex-start;
  margin-bottom: -200px;
  min-height: 1200px;
}

.wrap-page-container {
  margin: auto;
  border: 2px solid $color-primary;
  max-width: 50%;
  border-radius: 20px;
  background-color: $color-white;

  @media screen and (max-width: $breakpoint-lg) {
    max-width: 60%;
  }

  @media screen and (max-width: $breakpoint-md) {
    max-width: 70%;
    margin-top: 120px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    max-width: 90%;
    margin-top: 120px;
  }
}

.wrapup-title {
  font-family: Slalom Sans;
  font-weight: 700;
  font-style: normal;
  font-size: 16;
  line-height: 20;
  text-align: center;
  margin: 50px 0px;
  padding: 0px 15px;
}

.wrapup-header {
  font-family: Slalom Sans;
  font-weight: 700;
  font-style: normal;
  font-size: 38px;
  line-height: 45px;
  text-align: center;
  margin-bottom: 75px;

  @media screen and (max-width: $breakpoint-md) {
    font-family: Slalom Sans;
    font-weight: 700;
    font-style: normal;
    font-size: 38px;
    line-height: 45px;
    text-align: center;
    margin-bottom: 75px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-family: Slalom Sans;
    font-weight: 700;
    font-style: normal;
    font-size: 38px;
    line-height: 45px;
    margin-bottom: 50px;
    padding-left: 22px;
    padding-right: 75px;
  }
}

.wrapup-content {
  font-family: Slalom Sans;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  padding: 0 35px;
  margin-bottom: 75px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: $breakpoint-sm) {
    font-family: Slalom Sans;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    padding: 0 22px;
    margin-bottom: 75px;
  }
}

.wrapup-button-container {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $breakpoint-md) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: $breakpoint-sm) {
    display: flex;
    flex-direction: column;
    margin: -80px 37px 28px 37px;
  }
}

.wrap-page-header {
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  font-size: 38px;
  line-height: 46px;
  margin: 0px 0px 32px 0px;
  padding: 0px 10px 0px 0px;
}

.wrap-page-header.mobile {
  margin: 48px 0px 32px 0px;
  padding-top: 150px;
  font-size: 32px;
  line-height: 38px;
}

.wrap-page-text {
  font-size: 16px;
  line-height: 19px;
  padding: 32px 24px 0 24px;
}

.wrap-page-text.desktop {
  padding: 0px 10px 0px 10px;
}

.wrap-button {
  width: 100%;
  margin: 12px 0;
  align-items: center;
  justify-content: center;
}

.wrap-button-flex {
  display: flex;
  justify-content: space-between;
}

.wrap-button-flex.mobile {
  flex-direction: column;
  align-items: center;
}

.top-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.top-buttons-tablet {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrap-page-bg-container {
  background-image: url("../../assets/images/bg-scl-2024.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  display: flex;
}

.wrap-image {
  width: 100%;
  border-radius: 20px 20px 0 0;
  margin-bottom: -10px;
}

.wrap-page-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding: 24px 24px 32px 24px;

  @media screen and (min-width: $breakpoint-lg) {
    flex-direction: row;
  }
}

.wrap-page-button {
  width: 100%;
}

.app-button-secondary {
  font-weight: 700;
}

div:has(.wrap-page-button) {
  width: 100%;
}